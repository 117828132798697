.blog{
  padding:90px 40px 40px 40px;
  display:flex;
  flex-direction:column;
  align-items:center;
  gap:40px;
}

.post{
  background-color:black;
  border-radius:20px;
  display:flex;
  width:100%;
}

.photoDiv, .textContainersDiv{
  width:50%;
}

.photoDiv{
  padding:30px;
}

.postPhoto{
  border-radius:20px;
  width:100%;
  height:375px;
}

.textContainersDiv{
  display:flex;
  flex-direction:column;
  justify-content:center;
  padding:30px;
  color:white;
}

/* ------------------------------------- BREAKPOINTS -------------------------------------- */
@media (max-width: 1200px) {
  .postPhoto{
    height:300px;
  }
}

@media (max-width: 750px) {
  .postPhoto{
    height:250px;
  }
}

@media (max-width: 550px) {
  .photoDiv{
    padding:0px;
  }
  .postPhoto1{
    border-radius:0 20px 20px 0;
  }
  .postPhoto2{
    border-radius:20px 0 0 20px;
  }
  .textContainersDiv{
    height:250px;
    .btn{
      padding:0;
    }
  }
  .blogPostText{
    font-size:1.2rem;
  }
}