.btn{
  border-radius:0;
}

.theArtistPageWrapper{
  padding:90px 110px 40px 110px;
}

.headSection{
  display:flex;
  gap:40px;
  margin-bottom:30px;
}

.artistPhoto{
  border-radius:50%;
  width:300px;
}

.infoDiv{
  display:flex;
  flex-direction:column;
  justify-content:center;
  p{
    margin:0;
  }
}

/* CONTENT SWITCHER */
.buttonGroup{
  margin-bottom:30px;
  width:fit-content;
}

body{
  overflow-x:clip;
}

/* THEARTISTGALLERY */
.mainPhotoDiv{
  padding:12px !important;
}

.relativeDiv{
  height:300px;
  border:2vh solid black !important;

}

.photoPositionReference{
  position:relative;
  /* bu alttaki ikisi ve absolutePhotonun transformu sol üste göre değil ortaya göre küçülmesini sağlıyor*/
  top: 50%;
  left: 50%;
}

.absolutePhoto{
  z-index:-1;
  height:300px;
  padding:3px;
  position:absolute;
  width:110%;
  transition: width 0.5s ease-in-out;
  transform: translate(-50%, -50%);
}

.relativeDiv:hover{
  .absolutePhoto{
    width:103%;
  }
}

/* STUDIO LOCATION */
.studioLocation{
  width:60%;
}

.locationPhoto{
  height:500px;
}

.locationPhoto, .locationButton{
  width:100%;
}

/* BOOKING DIV */
.formWrapper{
  display:flex;
  gap:5%;
  .bookingButton{
    margin:0 5%;
  }
}

.formsDiv{
  width:45%;
}

.bookingCard{
  width:50%;
}

.bookingCardImageDiv{
  width:100%;
  height:400px;
}

/* ------------------------------------- BREAKPOINTS -------------------------------------- */
@media (max-width: 1200px) {
  .artistPhoto{
    height:250px;
    width:250px;
  }
}

@media (max-width: 800px) {
  .artistPhoto{
    height:200px;
    width:200px;
  }
  .studioLocation{
    width:80%;
  }
}

@media (max-width: 675px) {
  .artistPhoto{
    height:175px;
    width:175px;
  }
}

@media (max-width: 600px) {
  .theArtistPageWrapper{
    padding:90px 40px 40px 40px;
  }
  .headSection{
    flex-direction:column;
    align-items:center;
    gap:10px;
    margin-bottom:20px;
  }
  .buttonGroup{
    margin:auto;
  }
  .artistPhoto{
    height:150px;
    width:150px;
  }
  .infoDiv{
    align-items:center;
    text-align:center;
  }
  .buttonGroup{
    margin-bottom:20px;
  }
  .studioLocation{
    margin:auto;
  }
  .locationPhoto{
    height:350px;
  }
   input, select{
    font-size:0.6rem !important;
    padding:4px 1px !important;
  }
}


@media (max-width: 450px) {
  .buttonGroup button{
      font-size: 0.8rem;
  }
  input, select{
    font-size:0.5rem !important;
  }
}

@media (max-width: 400px) {
  input, select{
    font-size:0.45rem !important;
  }
}
