.signInWrapper{
  height:100vh;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
}
.signInCard{
  width:400px;
  padding:20px 0;
  height:fit-content !important;
}

.newArtistOrLoginButtonSecond{
 margin-left:20px;
}

/* ------------------------------------- BREAKPOINTS -------------------------------------- */
@media (max-width: 600px) {
  .signInCard{
    width:80% ;
  }
}