.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0%{
    background-color: hsl(240, 0%, 30%);
  }

  100%{
    background-color: hsl(240, 0%, 50%);
  }
}

/* ---------------------------------- STATIC PROPERTIES ----------------------------------- */
/* WRAPPER */
.homeWrapper{
  position:relative;
  overflow-x:clip !important;
}

.mainPhoto{
  position:absolute;
  width:100%;
}

.divThatSlidesOverWrapper{
  z-index:1;
  position:absolute;
  width:100%;
  background-color:#EEEEEE;
  margin-top:850px;
  font-size:1.8rem !important;
}

/* BIG PHOTOS WITH PAGE DIRECTIONS */
.bigPhotosWithPageDirections{
  display:flex;
  div{
    width:49.5%;
  }
  img{
    width:50.5%;
  }
}

.bigPhotosWithPageDirections div{
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
}

.bigPhotosWithPageDirections div div{
  align-items:flex-start;
  width:90% !important;
}

/* CAROUSEL */
.myCarouselDiv{
  padding:50px;
  width:60%;
  margin:auto;
  display:flex;
  flex-direction:column;
  align-items:center;
  h1{
    margin-bottom:20px;
  }
}

/* CARDS */
.slidingImages{
  height:70vh;
  display:flex;
  flex-direction:column;
  justify-content:center;
}

.cardsContainer{
  position:absolute;
  width:300%;
}

.cardSlideImages{
  display:inline-block !important;
}

.cardImage{
  height:60vh;
  width:20vw;
}

/* BOTTOM FIXED BUTTONS */
.container1{
  width:100vw;
  display:flex;
  margin:10px;
  bottom:10px;
  position:fixed;
  justify-content:center;
}

.rounded-half-height{
  width:30%;
  .btn {
    background-color:black;
    width:100%;
    border-radius:20px !important;
  }
}

/* CHATBOT */
.chatbotContainer{
  position:fixed;
  right:10px;
  bottom:10px;
  width:25%;
  z-index:1;
  display:flex;
  flex-direction:column;
  align-items:end;
}

.chatbotDiv{
  background-color:#F5F5F5 !important;
  border-radius:5%;
  height:400px;
  width:100%;
}

.chatbotDivFirstChild{
  border-bottom:3px black solid !important;
  .chatbotBrand{
    width:fit-content;
    margin:auto;
  }
}

.chatbotDivSecondChild{
  height:290px;
  padding:10px;
  .chatbotSlideFirst{
    position:relative;
    height:100%;
    width:100%;
  }
  .chatbotSlideSecond{
    position:absolute;
    width:100%;
    height:100%;
    overflow-y:scroll !important;
    overflow:hidden;
    font-size:1.3rem !important;
  }
  .chatbotChatFirstDiv{
    border-radius:10px 0 10px 10px;
    margin-left:50px !important;
    background-color:white;
    padding:0 10px;
  }
  .chatbotChatSecondDiv{
    border-radius:0 10px 10px 10px;
    margin-right:50px !important;
    background-color:white;
    padding:0 10px;
  }
}

.chatbotDivThirdChild{
  margin:0 20px;

  .chatbotForm{
    position: relative;
    .chatbotFormInput{
      padding-right:50px;
    }
  }
  .chatbotFormButton{
    position:absolute;
    right:0;
    top:3px;
  }
}

.chatbotIconDiv{
  background-color:#EEEEEE !important;
  border-radius:50%;
  width:fit-content;
  margin-top:5px;
  padding:5px;
}

.sendingPhoto, .crossPhoto{
  width:30px;
  height:30px;
}

.iconPhoto{
  width:40px;
  height:40px;
}

/* ------------------------------------- BREAKPOINTS -------------------------------------- */
@media (max-width: 1280px) {
  /* NAVBAR */
  .border{
    font-size:1.5rem;
  }

  /* WRAPPER */
  .divThatSlidesOverWrapper{
    margin-top:600px;
  }

  /* CAROUSEL */
  .myCarouselDiv{
    padding:40px;
  }
  .carouselsText{
    font-size:1.5rem
  }

  /* CARDS */
  .cardImage, .cardSlideImages{
    height:50vh;
    width:25vw;
  }

  /* BOTTOM FIXED BUTTONS */
  .container1{
    top:530px;
  }

  /* CHATBOT */
  .chatbotContainer{
    width:40%;
  }

  .chatbotDivSecondChild{
    .chatbotSlideSecond{
      font-size:1.5rem !important;
    }
  }
}

@media (max-width: 890px) {
  /* NAVBAR */
  .container{
    margin:0;
  }

  /* WRAPPER */
  .divThatSlidesOverWrapper{
    margin-top:500px;
  }

  /* CAROUSEL */
  .carouselsText{
    font-size:1.2rem
  }

  /* CARDS */
  .cardImage{
    height:50vh;
    width:25vw;
  }

   /* BOTTOM FIXED BUTTONS */
  .container1{
    top:430px;
  }

  /* CHATBOT */
  .chatbotContainer{
    width:50%;
  }

  .chatbotDivSecondChild{
    .chatbotSlideSecond{
      font-size:1.7rem !important;
    }
  }
}

@media (max-width: 760px) {
  /* WRAPPER */
  .divThatSlidesOverWrapper{
    margin-top:400px;
    font-size:1.4rem !important;
  }

  /* CAROUSEL */
  .myCarouselDiv{
    width:80%;
  }

  /* CARDS */
  .slidingImages, .cardsContainer{
    height:40vh;
  }
  .slidingImages{
    margin-bottom:20px;
  }

  .cardImage{
    height:40vh;
    width:25vw;
  }

   /* BOTTOM FIXED BUTTONS */
  .container1{
    top:320px;
  }

  /* CHATBOT */
  .chatbotContainer{
    width:55%;
  }

  .chatbotDiv{
    height:400px;
  }

  .chatbotDivSecondChild{
    .chatbotSlideSecond{
      font-size:1.8rem !important;
    }
  }

  .chatbotFormInput{
    height:50px;
    font-size:1.5rem !important;
  }

  .IconPhoto{
    width:50px;
    height:50px;
  }
}

@media (max-width: 600px) {
  /* WRAPPER */
  .divThatSlidesOverWrapper{
    margin-top:300px;
  }

  /* BIG PHOTOS WITH PAGE DIRECTIONS */
  .bigPhotosWithPageDirections{
    margin:20px 0;
    flex-direction:column;
    align-items:center;
    div, img{
      width:90%;
    }
    .text{
      margin-bottom:0;
    }
  }

  .bigPhotosWithPageDirections div{
    align-items:start;
  }

  .bigPhotosWithPageDirections div div{
    padding:0;
  }

  /* CAROUSEL */
  .myCarouselDiv{
    padding:20px;
  }
  .carouselsText{
    font-size:1rem
  }

  /* CARDS */
  .slidingImages{
    margin:40px 0;
  }

  .cardImage{
    height:40vh;
    width:30vw;
  }

   /* BOTTOM FIXED BUTTONS */
  .container1{
    top:240px;
  }
  .rounded-half-height{
    width:50%;
  }

  /* CHATBOT */
  .chatbotContainer{
    width:75%;
  }
}

@media (max-width: 460px) {
  /* WRAPPER */
  .divThatSlidesOverWrapper{
    margin-top:240px;
  }

   /* BOTTOM FIXED BUTTONS */
  .container1{
    top:180px;
  }

   /* CAROUSEL */
  .myCarouselDiv{
    width:90%;
  }
  .carouselsText{
    font-size:0.8rem
  }

  /* CARDS */
  .slidingImages, .cardsContainer{
    height:28vh;
  }
  .cardImage{
    height:28vh;
    width:30vw;
  }

  /* CHATBOT */
  .chatbotContainer{
    width:95%;
  }

  .chatbotDiv{
    height:400px;
  }

}

@media (max-width: 400px) {
  /* CARDS */
  .slidingImages, .cardsContainer{
    height:25vh;
  }
  .cardImage{
    height:25vh;
    width:30vw;
  }
}