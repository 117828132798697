.blogPostWrapper{
  padding:80px 40px;
}

.thePostPhotos{
  width:60%;
  margin:10px 0;
}

/* ------------------------------------- BREAKPOINTS -------------------------------------- */
@media (max-width: 800px) {
  .thePostPhotos{
    width:80%;
  }
}

@media (max-width: 600px) {
  .thePostPhotos{
    width:100%;
  }
}