.newArtistOrLoginWrapper{
  height:100vh;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
}
.newArtistOrLoginCard{
  padding:20px 0;
  height:fit-content !important;
}

.newArtistOrLoginButtonSecond{
 margin-left:15px;
}

