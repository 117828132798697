/* FILTERS AND BUTTONS */
.galleryPageWrapper{
  padding:90px 40px 40px 40px;
}

.galleryFiltersDiv{
  display:flex;
  justify-content:center;
  gap:30px;
  margin-bottom:28px;
}

.galleryFiltersDiv select{
  border-width:5px;
  border-color:black;
  font-size:1.4rem;
}

/* GALLERY PHOTOS */
.photoLink{
  padding:12px;
}

.galleryPhotoDiv{
  height:300px;
  border:2vh solid black !important;
}

.galleryPhotoReference{
  position:relative;
  /* bu alttaki ikisi ve absolutePhotonun transformu sol üste göre değil ortaya göre küçülmesini sağlıyor */
  top:50%;
  left:50%;
}

.galleryAbsolutePhoto{
  z-index:-1;
  height:300px;
  padding:3px;
  position:absolute;
  width:110%;
  transition: width 0.5s ease-in-out;
  transform: translate(-50%, -50%);
}

.galleryPhotoDiv:hover{
  .galleryAbsolutePhoto{
    width:103%;
  }
}

/* ------------------------------------- BREAKPOINTS -------------------------------------- */
@media (max-width: 750px) {
  .filtersDiv{
    gap:20px;
  }
}

@media (max-width: 600px) {
  .galleryFiltersDiv{
    gap:10px;
    select{
      font-size:1.2rem !important;
      padding:7px !important;
    }
  }
}

@media (max-width: 575px) {
  .galleryAbsolutePhoto{
    width:103%;
  }
}

@media (max-width: 400px) {
  .galleryFiltersDiv{
    gap:3px;
    select{
      font-size:0.9rem;
      padding:5px;
    }
  }
}