.profileWrapper{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
}

.profileCard{
  margin-top:60px;
  width:80%;
  height:fit-content !important;
}

.profileInnerCard{
  width:90%;
  height:fit-content !important;
}

/* ------------------------------------- BREAKPOINTS -------------------------------------- */
@media (max-width: 600px) {
  .profileCard{
    width:80% ;
  }
}