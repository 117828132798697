.navbar {
  background-color:transparent !important;
  position:fixed;
  transition: background-color 0.5s ease-in-out;
  font-size:2rem !important;
  z-index:2;
  width:100%;
  .pageDirection{
    color:black;
  }
}

.homePageNavbar{
  .pageDirection{
    font-weight:400 important;
    color:#E8E8E8;
  }
}

.navbar:hover{
  background-color:#F5F5F5 !important;
  transition: background-color 0.5s ease-in-out;
  .pageDirection{
    color:black;
  }
}

.scrolledNavbar{
  background-color:#F5F5F5 !important;
  transition: background-color 0.5s ease-in-out;
  .pageDirection{
    color:black;
  }
}

.navbarTogglerWhite{
  background-color:white;
}

.navbarLinksContainer{
  margin:auto;
  display:flex;
  justify-content:center;
  align-items:center;
  gap:90px;
}

.nav-link{
  height:fit-content;
  padding:0px;
  border-width:0px !important;
}

.logo{
  height:60px;
  width:60px;
}

.navbarBrand{
  margin:auto;
}

/* FOOTER */
.footer {
  background-color:black;
  color:white;
  padding: 5px 0;
}

.footer-container {
  display:flex;
  flex-direction:column;
  align-items:center;
  position:relative;
}

.upperFooter{
  display:flex;
  align-items:center;
  gap:10vw;
}

.logoContainer{
  width:40vw;
  display:flex;
  img{
    margin:5px 0 10px 10px;
  }
}

.iconContainer{
  width:40vw;
  display:flex;
  justify-content:end;
  margin-bottom:5px;
  p{
    margin:0px;
  }
}

.iconContainerInsideDiv{
  display:flex;
  flex-direction:column;
  align-items:center;
  margin-left:5px;
}

.copyright {
  margin:0px auto 0px auto;
  font-size:1rem;
}

/* ------------------------------------- BREAKPOINTS -------------------------------------- */
@media (max-width: 1500px) {
  .navbar {
    font-size:1.6rem !important;
  }
}

@media (max-width: 890px) {
  .container{
    margin:auto;
  }
}

@media (max-width: 767px) {
  .container{
    max-width:100%;
  }
  .navbarLinksContainer{
    align-items:center;
    gap:20px;
  }
  .navbarLogoContainer{
    display:flex;
    justify-content:center;
    margin-bottom:20px;
  }
}

@media (max-width: 760px) {


  .slogan{
    font-size:0.9rem;
  }
}

@media (max-width: 500px) {
  .upperFooter{
    gap:0;
    margin:0 10px;
  }
  .logoContainer{
    width:25vw;
  }
  .iconContainer{
    width:65vw;
  }

  .copyright {
    font-size:0.5rem;
  }
}
