.pageWrapper{
  display:flex;
  gap:5%;
  padding:90px 40px 40px 40px;
}

.newArtistPhotoDiv{
  width:60%;
}

.newArtistPhoto{
  height:100%;
  width:100%;
}

.newArtistForm{
  width:30%;
}

.areYouAnArtistReturnText{
  display:flex;
  flex-direction:column;
  justify-content:center;
  height:80vh;
}

/* ------------------------------------- BREAKPOINTS -------------------------------------- */
@media (max-width: 900px) {
  .pageWrapper{
    flex-direction:column;
  }
  .newArtistPhotoDiv{
    width:80%;
    margin:auto;
  }
  .newArtistForm{
    width:80%;
    margin:10px auto;
  }
  .areYouAnArtistReturnText{
    width:80%;
    margin:5px auto;
    height:20vh;
  }
}