/* FILTERS AND BUTTONS */
.artistsWrapper{
  padding:90px 40px 40px 40px;
}

.filtersDiv{
  display:flex;
  justify-content:center;
  gap:30px;
}

.filtersDiv select{
  border-width:5px;
  border-color:black;
  font-size:1.4rem;
}

.button-group-div{
  position:relative;
  height:40px;
  margin:20px 0;
}

.btn-group{
  position:absolute;
  right:0px;
}

/* TATTOOERS PHOTOS */
.artistsPhotosDiv{
  /* Bunu vermemin sebebi üstteki kod ile padding içeri kayınca filtrelerle hizasının bozulmaması */
  margin:0 -15px -30px -15px;
  .artistCardWrapper{
    padding:0 15px 30px 15px;
  }
  .card{
    position:relative;
    border-color:black;
    border-width:5px;
    /* bu margin varmış gibi küçültüyo ve griddeki 12 sistemi kaybetmemizi engelliyo */
    height:478px;
  }
}

.card-img-top{
  border-radius:50%;
  height:280px;
  margin:10px 0px;
}

.topRightButton {
  position:absolute;
  top:0px;
  right:0px;
  border-color:black;
  border-width:0px 0px 5px 5px;
  border-radius:0px 0px 0px 6px;
}

.overlay{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:85%;
}

.overlayFirstChild{
  position:relative;
  height:100%;
  width:100%;
}

.overlaySecondChild{
  position:absolute;
  height:100%;
  overflow-y:scroll !important;
  overflow:hidden;
}

.overlayImg{
  padding:0px;
  border:2px solid black;
}

.btn-close{
  position:absolute;
  bottom:15px !important;
  left:15px;
  transform:scale(1.5);
}

.bookingButton{
  border-color:black;
  border-width:5px;
  margin-top:30px;
}

.bookingButtonFirst{
  position:absolute;
  bottom:15px;
  left:16px;
}

.bookingButtonSecond{
  position:absolute;
  bottom:10px;
  right:16px;
}

/* ------------------------------------- BREAKPOINTS -------------------------------------- */
@media (max-width: 1500px) {
  .card{
    font-size:1.5rem !important;
    h1{
      font-size:1.9rem !important;
    }
  }
}

@media (max-width: 1200px) {
  .card-img-top{
    height:250px;
  }
  .card{
    height:434px !important;
    font-size:1.3rem !important;
    h1{
      font-size:1.5rem !important;
    }
  }
}

@media (max-width: 750px) {
  .filtersDiv{
    gap:20px;
  }
  .card-img-top{
    height:250px;
  }
}

@media (max-width: 600px) {
  .filtersDiv{
    gap:10px;
    select{
      font-size:1.2rem !important;
      padding:7px !important;
    }
  }
  .btn-group{
    right:3px;
    button{
      font-size: 1rem;
      padding: 8px 12px;
    }
  }
}

@media (max-width: 575px) {
  .card-img-top{
    height:350px !important;
  }
  .card{
    height:530px !important;
  }
}

@media (max-width: 450px) {
  .card-img-top{
    height:300px !important;
  }
  .card{
    height:480px !important;
  }
}

@media (max-width: 400px) {
  .filtersDiv{
    gap:3px;
    select{
      font-size:0.9rem;
      padding:5px;
    }
  }
}